import ProductRepository from '../../repositories/ProductRepository'

const state = {
  products: [],
  product: {},
  categories: [],
  tags: [],
  error: ''
}

const getters = {
}

const actions = {
  async get({ commit }) {
    await ProductRepository.get()
      .then(resp => {
        commit('setProducts', resp.data.data)
      })
  },
  async categories({ commit }) {
    await ProductRepository.categories()
      .then(resp => {
        commit('setCategories', resp.data.data)
      })
  },
  async detail({ commit }, id){
    await ProductRepository.detail(id)
      .then(resp => {
        commit('setProduct', resp.data.data)
      })
  },
  async put({ commit, dispatch }, payload){
    await ProductRepository.put(payload)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  async post({ commit }, payload){
    await ProductRepository.post(payload)
      .then(() => {
        commit('setError', '')
      }).catch(error => {
        commit('setError', error.response.data.message)
      })
  },
  async delete({ commit, dispatch }, id){
    await ProductRepository.delete(id)
      .then(() => {
        commit('setError', '')
        dispatch('get')
      })
  },
  setError({ commit }){
    commit('setError', '')
  },
}

const mutations = {
  setProducts (state, data) {
    state.products = data
  },
  setProduct (state, data) {
    state.product = data
  },
  setCategories (state, data) {
    state.categories = data
  },
  setTags (state, data) {
    state.tags = data
  },
  setError(state, msg){
    state.error = msg
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
