<template>
  <main class="content">
    <loading :isLoading="loaded" />
    <div class="container-fluid p-0">
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto d-none d-sm-block text-center">
          <h3 v-if="!$route.params.id">Add new product</h3>
          <h3 v-else>Update product</h3>
        </div>
      </div>
    </div>
    <hr />
    <form @submit.prevent="onSave()" method="post" v-if="!loaded">
      <div
        class="alert alert-danger alert-dismissible"
        role="alert"
        v-if="error"
      >
        <div class="alert-message">
          <strong>Hello there!</strong> {{ error }}
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Name</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.name"
            :class="[$v.form.name.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.name.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.name.required"
            >
              Name is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Description</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            v-model="form.description"
            :class="[$v.form.description.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.description.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.description.required"
            >
              Description is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Content</label>
        <div class="col-sm-10">
          <ckeditor
            v-model="form.content"
            :class="[$v.form.content.$error ? 'input-error' : '']"
          ></ckeditor>
          <div v-if="submitted && $v.form.content.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.content.required"
            >
              Content is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Category</label>
        <div class="col-sm-10">
          <treeselect
            v-model="form.categories"
            :multiple="true"
            :options="categories"
            :class="[$v.form.categories.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.categories.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.categories.required"
            >
              Category is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Regular Price</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            type="number"
            min="0"
            v-model="form.regular_price"
            :class="[$v.form.regular_price.$error ? 'input-error' : '']"
          />
          <div v-if="submitted && $v.form.regular_price.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.regular_price.required"
            >
              Regular price is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Sale Price</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            type="number"
            min="0"
            v-model="form.sale_price"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Weight</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            type="number"
            min="0"
            v-model="form.weight"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Stock</label>
        <div class="col-sm-10">
          <input
            class="form-control"
            type="number"
            min="0"
            v-model="form.stock"
          />
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Avatar</label>
        <div class="col-sm-10">
          <UploadImages :max="1" @change="handleAvatar" :images="form.avatar" />
          <div v-if="submitted && $v.form.avatar.$error">
            <p
              class="invalid-feedback"
              style="text-left"
              v-if="!$v.form.avatar.required"
            >
              Avatar is Required!
            </p>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-form-label col-sm-2 text-sm-end">Images</label>
        <div class="col-sm-10">
          <UploadImages :max="10" @change="handleImages" :images="form.images"/>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-sm-10 ms-sm-auto">
          <button type="submit" class="btn btn-success">Save</button>
        </div>
      </div>
    </form>
  </main>
</template>

<script>
import UploadImages from "../../components/UploadImages"
import { mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import Treeselect from "@riophae/vue-treeselect"
import "@riophae/vue-treeselect/dist/vue-treeselect.css"

export default {
  data() {
    return {
      form: {
        id: 0,
        name: "",
        description: "",
        content: "",
        regular_price: 0,
        sale_price: null,
        stock: 0,
        avatar: "",
        images: [],
        weight: 0,
        categories: []
      },
      loaded: true,
      submitted: false
    }
  },
  components: {
    UploadImages,
    Treeselect
  },
  validations: {
    form: {
      name: { required },
      description: { required },
      content: { required },
      regular_price: { required },
      sale_price: { numeric },
      stock: { numeric },
      weight: { numeric },
      avatar: { required },
      categories: { required }
    }
  },
  computed: {
    ...mapState({
      error: (state) => state.product.error,
      product: (state) => state.product.product
    }),
    categories() {
      const categories = this.$store.state.product.categories
      return categories.map((item) => {
        const children = item.children.map((child) => {
          return {
            id: child.id,
            label: child.name
          }
        })
        const cate = {
          id: item.id,
          label: item.name
        }
        if (children.length > 0) {
          cate.children = children
        }
        return cate
      })
    }
  },
  async mounted() {
    await this.$store.dispatch("product/categories")
    if (this.$route.params.id) {
      await this.getDetail(this.$route.params.id)
    }
    this.loaded = false
  },
  methods: {
    async getDetail(id) {
      await this.$store.dispatch('product/detail', id)
      const categories = this.product.categories.map((item) => item.id)
      this.form = {
        id: this.product.id,
        name: this.product.name,
        description: this.product.description,
        content: this.product.content,
        regular_price: this.product.regular_price,
        sale_price: this.product.sale_price,
        stock: this.product.stock,
        avatar: [this.product.avatar],
        images: this.product.images,
        weight: this.product.weight,
        categories
      }
      console.log(this.form)
    },
    handleImages(imgs) {
      this.form.images = imgs
      console.log(this.form)
    },
    handleAvatar(imgs) {
      this.form.avatar = imgs
      console.log(this.form)
    },
    async onSave() {
      this.submitted = true
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      this.loaded = true
      const params = {
        id: this.form.id,
        name: this.form.name,
        description: this.form.description,
        content: this.form.content,
        regular_price: this.form.regular_price,
        sale_price: this.form.sale_price,
        stock: this.form.stock,
        weight: this.form.weight,
        avatar: this.form.avatar[0],
        images: this.form.images,
        categories: this.form.categories
      }
      if (!this.$route.params.id) {
        await this.$store.dispatch("product/post", params)
      } else {
        await this.$store.dispatch("product/put", params)
      }
      if (!this.error) {
        this.loaded = false
        this.$router.push("/products")
      } else {
        this.loaded = false
      }
    }
  }
}
</script>