import Repository from './Repository'

export default {
  get(payload) {
    return Repository.get('/products', payload)
  },
  post(payload) {
    return Repository.post('/products', payload)
  },
  detail(id) {
    return Repository.get('/products/' + id)
  },
  put(payload) {
    return Repository.put('/products/' + payload.id, payload)
  },
  delete(id) {
    return Repository.delete('/products/' + id)
  },
  categories(payload) {
    return Repository.get('/categories', payload)
  },
  tags(payload) {
    return Repository.get('/tags', payload)
  },
}

